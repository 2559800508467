import React, { Fragment } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import {
  Column,
  SEOTitle,
  Row,
  Button,
  Container,
  Text,
  Wrapper,
} from "../theme/index"
import { Responsive } from "../theme/styles"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const StyledButton = styled(Button)`
  margin: 10px 0;
`

const StyledColumn = styled(Column)`
  ${Responsive.sm`
        margin-right: 0px
    `}
  ${Responsive.xlg`
        margin-right: 20px;
    `}
`

const ButtonColumn = styled(Column)`
  ${Responsive.sm`
        margin-bottom: 30px;
    `}
  ${Responsive.xlg`
        margin-bottom: 0px;
    `}
`

const StyledRow = styled(Row)`
  ${Responsive.sm`
    width: 60%;
    margin: 30px auto 0 auto;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-itmes: center;
    `}
  ${Responsive.xlg`
    flex-direction: row;
    align-items: center;
    margin: 50px auto 0 auto;
    `}
`

const Links = () => {
  const data = [
    {
      text: "Web Conversions Guide",
      link: "/landing-page/free-resource",
      internal: true,
    },
    {
      text: "Website Audit Deal",
      link: "/landing-page/website-consultation",
      internal: true,
    },
    {
      text: "Talkin' Solutions Podcast",
      link: "/talkin-solutions-podcast",
      internal: true,
    },
    {
      text: "Website in 10 Days!",
      link: "/landing-page/affordable-website-solution",
      internal: true,
    },

    {
      text: "Services",
      link: "/services",
      internal: true,
    },
    {
      text: "CheshTech IG",
      link: "https://www.instagram.com/cheshtech/",
      internal: false,
    },
    {
      text: "Podcast IG",
      link: "https://www.instagram.com/talkinsolutionspodcast/",
      internal: false,
    },
    {
      text: "LinkedIn",
      link: "https://www.linkedin.com/company/cheshtech",
      internal: false,
    },
    {
      text: "Twitter",
      link: "https://twitter.com/CheshTech_",
      internal: false,
    },
    {
      text: "Facebook",
      link: "https://facebook.com/CheshTech_",
      internal: false,
    },
    {
      text: "Website Home",
      link: "/",
      internal: true,
    },
  ]

  return (
    <Layout title="CheshTech Links">
      <Wrapper>
        <Container>
          <SEOTitle textAlign="center">Connect With Us!</SEOTitle>
          <StyledRow>
            <StyledColumn>
              <StaticImage
                src="../images/homeWill.jpg"
                alt="CheshTech William Cheshier"
                placeholder="blurred"
                //   layout="fixed"
                width={400}
                height={600}
              />
              <Text margin="10px 0 0 0">CheshTech CEO: Will Cheshier</Text>
            </StyledColumn>
            <ButtonColumn>
              {data.map((key, index) => {
                return (
                  <Fragment key={index}>
                    {key.internal === false && (
                      <a href={key.link} target="__blank">
                        <StyledButton>{key.text}</StyledButton>
                      </a>
                    )}
                    {key.internal === true && (
                      <Link href={key.link}>
                        <StyledButton>{key.text}</StyledButton>
                      </Link>
                    )}
                  </Fragment>
                )
              })}
            </ButtonColumn>
          </StyledRow>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default Links
